<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data () {
    return {};
  },
  watch: {},
  created () {
    //检测用户是否登录
    console.log(this.$ipc);
  },
  methods: {},
};
</script>
<style lang="less">
@import url(./../public/css/common.less);

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1760px;
  height: 990px;
  font-size: 15px;
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
</style>
